import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import AuthComponent from "./components/Auth/Login/AuthComponent";
import ForgetPwd from './components/Auth/forgetPwd';
import ResetPwd from './components/Auth/resetPwd';
const loading = () => (
  <div className="animated fadeIn pt-3 text-center">chargement...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

const Login = React.lazy(() => import("./components/Auth/Login/Login"));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              exact
              path="/Login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/forget-pwd"
              name=" Page"
              render={(props) => <ForgetPwd {...props} />}
            />
            <Route
              exact
              path="/reset-pwd"
              name="Reset Page"
              render={(props) => <ResetPwd {...props} />}
            />
            <AuthComponent>
              <Route
                path="/"
                name="dashboard"
                render={(props) => <DefaultLayout {...props} />}
              />
            </AuthComponent>
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
