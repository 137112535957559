import React, { Component } from "react";
import "./style.css";
import { post_url_authentification, post_url_imei, reset_password } from "../../../api/base";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Input,
  NavbarBrand,
} from "reactstrap";
import axios from "axios";
import ReactLoading from "react-loading";
import swal from "sweetalert";
import Swal from "sweetalert2";
export default class ResetPwd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            new_password:undefined,
            new_password1:undefined,
         token : new URLSearchParams(this.props.location.search).get("token"),
          errors: "",
          done: true,
          done_inscrit: true,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
      }
    
      change(e) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    
      handleSubmit(event) {
        event.preventDefault();
        if (this.validate()) {
          swal({
            title: "Voulez vous confirmer la réinitialisation du mot de passe ?",
            buttons: true,
          }).then((willDelete) => {
            if (willDelete) {
              this.setState({ done: false });
              axios({
                method: "post",
                url: reset_password(),
                data: {
                  new_password: this.state.new_password,
                },
                params:{ token:this.state.token},
              })
                .then((res) => {
                //  (res,"reeeeeeees")
                  this.setState({ done: true });
                  if (res.status == 200) {
                    swal("Mot de passe réinitialisé avec succès", {
                      icon: "success",
                    });
                  } else {
                    swal({
                      title: "Erreur",
                      text: "Une erreur s'est produite veuillez réessayer",
                      icon: "warning",
                      dangerMode: true,
                    });
                  }
                })
                .catch(function (error) {
                  try {
                    this.setState({ done: true });
                  } catch {}
                  if (error.message == "Request failed with status code 400") {
                    Swal.fire({
                      icon: "info",
                      title: "Session expirée!",
                      text: "Veuillez réessayer",
                    }).then((value) => {
                      localStorage.clear();
                window.location = "/#/Login";
                    });
                  } else {
                    Swal.fire({
                      icon: "error",
                      title: "Oups...",
                      text: error.message,
                    }).then((value) => {
                      //window.location.reload();
                    });
                  }
                });
            } else {
              Swal.fire("Operation annulée", "question");
            }
          });
        }
      }
    



      validate() {
        let errors = {};
        const { new_password} = this.state;
    
        let isValid = true;
    
        if (!this.state.new_password) {
          isValid = false;
    
          errors["new_password"] = "Veuillez saisir votre nouveau mot de passe.";
        }
        if (!this.state.new_password1) {
          isValid = false;
          errors["new_password1"] =
            "Veuillez saisir votre mot de passe de confirmation.";
        }
    
        if (
          typeof this.state.new_password !== "undefined" &&
          typeof this.state.new_password1 !== "undefined"
        ) {
           if (new_password.length < 3) {
            isValid = false;
            errors["new_password"] = "Doit contenir au moins 3 caractères.";
            // errors["new_password1"] = "Doit contenir au moins 3 caractères.";
          }
          
          if (this.state.new_password != this.state.new_password1) {
            isValid = false;
    
            errors["new_password"] = "Les mots de passe ne correspondent pas.";
            errors["new_password1"] = "Les mots de passe ne correspondent pas.";
          }
         
        }
        this.setState({
          errors: errors,
        });
        return isValid;
      }

    render() {
        return (
            <div className="background">
            <div className="app flex-row align-items-center">
              <Container>
                <Row className="justify-content-center">
                  <Col md="8">
                    <CardGroup>
                      <Card className="p-5">
                        <CardBody>
                          <Form onSubmit={this.handleSubmit}>
                            <NavbarBrand href="/#/">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/main/logoKato.png"
                                }
                                height="80"
                                alt="logo"
                              />
                            </NavbarBrand>
    
                            <h1>Réinitialiser le mot de passe</h1>
                            <p className="text-muted">
                              {" "}
                              Saisissez votre nouveau mot de passe
                            </p>
                        
                            <InputGroup className="mb-4">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="icon-lock"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                className="form-item"
                                placeholder=" Nouveau mot de passe"
                                name="new_password"
                                type="password"
                                onChange={(e) => this.change(e)}
                              />
                                <div className="text-danger">
                                   {this.state.errors.new_password}
                               </div>
                            </InputGroup>
                            <InputGroup className="mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="icon-user"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                className="form-item"
                                placeholder="Confirmer nouveau mot de passe"
                                name="new_password1"
                                type="password"
                                onChange={(e) => this.change(e)}
                              />
                                <div className="text-danger">
                                  {this.state.errors.new_password}
                              </div>
                            </InputGroup>
                            <Row>
                              <Col xs="6">
                                <Button color="primary" className="px-4">
                                Réinitialiser
                                </Button>
                              </Col>
                              <Col xs="2" className="text-left">
                                {!this.state.done ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "11vh",
                                    }}
                                  >
                                    {" "}
                                    <ReactLoading
                                      type={"spin"}
                                      color={"dodgerblue"}
                                    />
                                  </div>
                                ) : (
                                  <Col xs="6"></Col>
                                )}
                              </Col>
                            </Row>
                          </Form>
                        </CardBody>
                      </Card>
                   
                    </CardGroup>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        )
    }
}
