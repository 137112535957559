import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

class AuthComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: undefined
    };
  }

  componentDidMount() {
    const jwt = localStorage.getItem("Auth-jwt-token");

    if (!jwt) {

      this.props.history.push('/Login')

      }

      this.setState({

        token: localStorage.getItem("Auth-jwt-token")
      })

  }

  render() {
    const { token } = this.state;
    if (token === undefined) {
      return (
        <div>
          chargement...
        </div>
      );
    }

    if (token === null) {

      this.props.history.push('/Login');
    }

    return this.props.children;
  }
}

export default withRouter(AuthComponent);
