import React, { Component } from "react";
import "./style.css";
import {
    post_forget_pwd,
} from "../../../api/base";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Input,
  NavbarBrand,
} from "reactstrap";
import axios from "axios";
import ReactLoading from "react-loading";
import swal from "sweetalert";
import Swal from "sweetalert2";
//import DesktopBreakpoint from "../../responsive_utilities/desktop_breakpoint";
//import { GetErrorMessage } from "../../../helpers";
import Axios from "axios";
//import { Box } from "@material-ui/core";
//import { Link } from "react-router-dom/cjs/react-router-dom.min";
export default class ForgetPwd extends Component {

    constructor(props) {
        super(props);
        this.state = {
          email: "",
          error: "",
          done: true,
          done_inscrit: true,
        };
      }
    change(e) {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }

      submit(e) {
        e.preventDefault();
    
        this.setState({ done: false });
       
        axios
          .post(
            post_forget_pwd(),
       
            {
                email: this.state.email,
            }
          )
          .then((res) => {
            try {
           
                if (res.status ==200) {
                  Swal.fire({
                    icon: "success",
                    title: "Email envoyé avec succès. Vérifiez votre boite de réception",
                   
                  }).then((value) => {
                    window.location.reload();
                  });
                  
                  }
            } catch {
              //swal("erreur serveur : verifier votre connexion")
              Swal.fire({
                icon: "info",
                title: "Problème de connexion",
                text: "verifier votre connexion !",
              }).then((value) => {
                window.location.reload();
              });
            }
          })
          .catch(function (error) {
            if (error.message == "Request failed with status code 400") {
              swal({
                title: "Email invalide",
                text: "votre email n'existe pas",
                icon: "warning",
                dangerMode: true,
              }).then((value) => {
                window.location.reload();
              });
              //this.setState({ done: false });
            } else if (error.message == "Network Error") {
              //swal("erreur serveur : verifier votre connexion")
              Swal.fire({
                icon: "info",
                title: "Pas de connexion internet...",
                text: "verifiez votre connexion !",
              }).then((value) => {
                window.location.reload();
              });
            } else {
              //swal("erreur serveur : "+ error.message)
              Swal.fire({
                icon: "info",
                title: "Problème de connexion",
                text: "verifiez votre connexion !",
              }).then((value) => {
                window.location.reload();
              });
            }
          })
      }

    render() {
        return (
            <div className="background">
            <div className="app flex-row align-items-center">
              <Container>
                <Row className="justify-content-center">
                  <Col md="8">
                    <CardGroup>
                      <Card className="p-4">
                        <CardBody>
                          <Form onSubmit={(e) => this.submit(e)}>
                            <NavbarBrand href="/#/khallasli">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/main/logoKato.png"
                                }
                                height="80"
                                alt="logo"
                              />
                            </NavbarBrand>
    
                            <h1>Mot de passe oublié? </h1>
                            <p className="text-muted">
                              {" "}
                              Envoyer votre email et vous allez recevoir les cordonnées
                            </p>
                            <InputGroup className="mb-3">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="icon-user"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                className="form-item"
                                placeholder="Email"
                                name="email"
                                type="text"
                                onChange={(e) => this.change(e)}
                              />
                            </InputGroup>
                            <Row>
                              <Col sm="12" className="text-left">
                                {!this.state.done ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "11vh",
                                    }}
                                  >
                                    <ReactLoading
                                      type={"spin"}
                                      color={"dodgerblue"}
                                    />
                                  </div>
                                ) : (
                                 <Col> 
                                   <Button
                                 color="primary"
                                 type="submit"
                                 className="px-4"
                               >
                               Envoyer
                               </Button>
                               </Col>
    
                                )}
                              </Col>
                            </Row>
                          </Form>
                        </CardBody>
                      </Card>
          
                    </CardGroup>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        )
    }
}
