import { BASE_URL } from "../config.dev";
export const post_url_authentification = () => {
  return BASE_URL + "/api/auth/signin";
};
export const post_url_register = () => {
  return BASE_URL + "/api/identity/register";
};
// export const post_url_imei = () => {
//   return BASE_URL + "/api/Auth/Imei";
// };

export const get_balance_wallet = () => {
  return BASE_URL + "/api/admin/balance/wallet";
};
// export const get_url_statistcs_voucher = () => {
//   return BASE_URL + "/api/admin/monthly/voucher";
// };
// export const get_url_statistcs_topup = () => {
//   return BASE_URL + "/api/admin/monthly/topup";
// };
// export const get_url_statistcs_bill = () => {
//   return BASE_URL + "/api/admin/monthly/bill";
// };
// export const get_url_statistcs_recharge = () => {
//   return BASE_URL + "/api/admin/monthly/recharge";
// };
// export const get_url_statistcs_paymee= () => {
//   return BASE_URL + "/api/admin/monthly/paymee";
// };
// export const get_url_statistcs_sobflouss = () => {
//   return BASE_URL + "/api/admin/monthly/sobflous";
// };
// export const transaction_payment = () => {
//   return BASE_URL + "/api/admin/facture";
// };

// export const transaction_sobflous = () => {
//   return BASE_URL + "/api/admin/sobflouss";
// };
// export const get_solde_sim = () => {
//   return BASE_URL + "/api/admin/balance/sim";
// };
// export const get_solde_card = () => {
//   return BASE_URL + "/api/admin/balance/carte";
// };
// export const get_stock = () => {
//   return BASE_URL + "/api/admin/stock/starting";
// };
export const get_stock_voucher = () => {
  return BASE_URL + "/api/admin/stock/voucher";
};
export const transaction_recharge = () => {
  return BASE_URL + "/api/admin/recharges";
};
export const post_pwd = () => {
  return BASE_URL + "/api/admin/reinitialiser_passe";
};
export const get_One_sale_point = () => {
  return BASE_URL + "/api/user";
};
export const get_sale_point_list = () => {
  return BASE_URL + "/api/admin/sale_points";
};
export const get_Transfert_list = () => {
  return BASE_URL + "/api/admin/transfer_payment";
};
export const get_Transfert_status = () => {
  return BASE_URL + "/api/admin/transfer_payment/status";
};
// export const get_voucher_list = () => {
//   return BASE_URL + "api/admin/transaction_voucher";
// };
// export const get_topup_list = () => {
//   return BASE_URL + "api/admin/transaction_topup";
// };

export const post_pdv = ()=> {
  return BASE_URL + "/api/auth/signup";
}
export const get_All_categories = ()=> {
  return BASE_URL + "/api/category";
}
export const get_All_salepoints = ()=> {
  return BASE_URL + "/api/salepoint";
}
export const post_salepoint=()=>{
  return BASE_URL+"/api/salepoint";
}
export const post_category=()=>{
  return BASE_URL+"/api/category";
}
export const get_All_user = ()=> {
  return BASE_URL + "/api/user";
}
export const transaction_Onea = () => {
  return BASE_URL + "/api/onea/transaction";
};
export const transaction_moov = () => {
  return BASE_URL + "/api/moov/transaction";
};
export const get_All_Services = ()=> {
  return BASE_URL + "/api/service";
}

export const post_commissions = ()=> {
  return BASE_URL + "/api/commission/multiple-commission";
}

export const get_all_intervenants = ()=> {
  return BASE_URL + "/api/intervenant";
}
export const get_history = ()=> {
  return BASE_URL + "/api/history";
}


export const get_url_statistcs = () => {
  return BASE_URL + "/api/stats";
};

export const get_historique_wallet_by_user = () => {
  return BASE_URL + "/api/history";
};
export const reset_password = () => {
  return BASE_URL + "/api/user/reset_password";
};
export const change_pwd = ()=>{
  return BASE_URL + "/api/user/change_password"
}

export const post_add_pdv = ()=>{
  return BASE_URL + "/api/registration"
}
export const get_all_demandes = ()=>{
  return BASE_URL + "/api/demand-feed"
}
export const put_alimenter_user = (id)=>{
  return BASE_URL + `/api/wallet/feed/${id}`
}
export const user_PDV = (id)=>{
  return BASE_URL + `/api/user/${id}`
}


export const admin_api =()=>{
  return BASE_URL + "/api/admin"

}
export const admin_api_id =(id)=> {
  return BASE_URL + `/api/admin/${id}`

}

export const delete_category = (id)=>{
  return BASE_URL + `/api/category/${id}`
}
export const delete_salepoint = (id)=>{
  return BASE_URL + `/api/salepoint/${id}`
}

export const update_admin = (id)=>{
  return BASE_URL + `/api/admin/${id}`
}
export const post_forget_pwd = () => {
  return BASE_URL + "/api/user/forgot_password";
};

export const demand_feed_byId=(id)=>{
  return BASE_URL +`/api/demand-feed/${id}`
}


export const get_filter_transaction_topup= ()=>{
  return BASE_URL +"/api/topup/transaction"
}



export const get_operator_solde =()=>{
  return BASE_URL + "/api/topup/getSolde"

}